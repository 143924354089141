import { useLoader } from '@react-three/fiber'
import { EffectComposer, SSR, Bloom, LUT } from '@react-three/postprocessing'
import { useControls } from 'leva'
import { LUTCubeLoader } from 'postprocessing'

export function Effects() {
  const texture = useLoader(LUTCubeLoader, '/F-6800-STD.cube')
  const { enabled, ...props } = {
    enabled: true,
    temporalResolve: true,
    STRETCH_MISSED_RAYS: true,
    USE_MRT: true,
    USE_NORMALMAP: true,
    USE_ROUGHNESSMAP: true,
    ENABLE_JITTERING: true,
    ENABLE_BLUR: true,
    temporalResolveMix:  0.9,
    temporalResolveCorrectionMix: 0.4, 
    maxSamples:0,
    resolutionScale:1,
    blurMix:  0.2,    
    blurExponent: 10,
    blurKernelSize:1,
    rayStep: 0.5,
    intensity: 5,
    maxRoughness:1,
    jitter: 0.,
    jitterSpread: 0.25,
    jitterRough: 0.1,
    roughnessFadeOut: 1,
    rayFadeOut:0,
    MAX_STEPS:20,
    NUM_BINARY_SEARCH_STEPS: 6,
    maxDepthDifference:10,
    maxDepth: 1,
    thickness: 10,
    ior:1.45
  }
  return (
    enabled && (
      <EffectComposer disableNormalPass>
        <SSR {...props} />
        <Bloom luminanceThreshold={0.5} mipmapBlur luminanceSmoothing={0} intensity={1.5} />
        <LUT lut={texture} />
      </EffectComposer>
    )
  )
}
