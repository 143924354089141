import { createRoot } from 'react-dom/client'
import { Stats } from '@react-three/drei'
import { Leva } from 'leva'
import './styles.css'
import App from './App'
import { useState } from 'react'


function Overlay() {
  const [ready, set] = useState(false)
  return (
    <>
      {ready && <App />}
      <div className={`fullscreen bg ${ready ? 'ready' : 'notready'} ${ready && 'clicked'}`}>
        <div className="stack">
          <span onClick={() => set(true)}>Enter</span>
        </div>
      </div>
    </>
  )
}

createRoot(document.getElementById('root')).render(
  <>
    <Overlay />
  </>
)
