import * as THREE from 'three'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, Float, Preload, OrbitControls, useHelper } from '@react-three/drei'
import { Effects } from './Effects'
import { SpotLightHelper } from 'three'
import { suspend } from 'suspend-react'

export default function App() {
  const [maDpr, setmaDpt] = useState(1)


  useEffect( () => {
  }, [])
  return (
    <>
      <Canvas gl={{ antialias: false, stencil: false }} dpr={[1, 1.5]} camera={{ position: [4.5, 0.35, 0], fov: 30 }}>
          <Track url="/2-Step-x-UK-Garage-type-beat - _LIGHTS_(Melodic).mp3" />
          <Hall position={[0, 0.98, 0]} />
          <Darth position={[-5, 0.8, 0.2]} rotation={[0, 2, 0]} scale={0.6} />
          <Effects />
          <Preload all />
          <OrbitControls 
            position={[0, 0., -5]}
            target={[0, 0.45, 0]}
            maxPolarAngle={Math.PI * 0.79} 
            minDistance={0}
            maxDistance={12}
          />
      </Canvas>
      <InfoLayout />
    </>

  )
}

function Hall({ ...props }) {
  const { scene } = useGLTF('/hall-transformed.glb')
  return <primitive object={scene} {...props} />
}

const Nike = ({ ...props }) => {
  const { scene } = useGLTF('/nike-logo.glb')
  return <primitive object={scene} {...props} />
}


function Darth({ ...props }) {
  const maSceneRef = useRef()
  const spotlightref = useRef()

  const { scene, materials, nodes } = useGLTF('/nike-a-m-270.glb')

  //useHelper(spotlightref, SpotLightHelper)

  useLayoutEffect(() => {

    scene.traverse((object) => {
      if (object instanceof THREE.Mesh) {
        object.castShadow = true;
        object.receiveShadow = true;

      }
    });

  }, [])

  useFrame( (state, delta) => {
    scene.rotation.y += delta 
    scene.rotation.x += delta
  })
  return (
  <>
      <spotLight intensity={0.0} castShadow ref={spotlightref} penumbra={1} position={[-20, 4, -3]}/>
      <primitive ref={maSceneRef} object={scene} {...props} scale={0.09} />
  </>)
}

function Track({url, ...props}){
  const [mobileDevice, setMobileDevice] = useState(true) 
  useEffect( () => {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      setMobileDevice(true)
    }else{
      setMobileDevice(false)
    }
  }, [])

  const ref = useRef()
  const refSpot = useRef()
  const { gain, context, update, data } = suspend(() => createAudio(url), [url])
  useEffect(() => {
    // Connect the gain node, which plays the audio
    gain.connect(context.destination)
    // Disconnect it on unmount
    return () => gain.disconnect()
  }, [gain, context])

  useFrame((state) => {
    let avg = update()
    let int = avg/150
    if(int > 1){
      int = 0.5
    }
    if(mobileDevice){
      ref.current.intensity = 0.
      refSpot.current.intensity = 1
    }else{
      ref.current.intensity = avg/130 - 0.5
      refSpot.current.intensity = avg/130 - 0.1
    }
  })
  return (
    <>
      <spotLight ref={refSpot} position={[5, 4, -5]} />
      <ambientLight ref={ref}/>
    </>
  )

}

async function createAudio(url) {
  // Fetch audio data and create a buffer source
  const res = await fetch(url)
  const buffer = await res.arrayBuffer()
  const context = new (window.AudioContext || window.webkitAudioContext)()
  const source = context.createBufferSource()
  source.buffer = await new Promise((res) => context.decodeAudioData(buffer, res))
  source.loop = true
  // This is why it doesn't run in Safari 🍏🐛. Start has to be called in an onClick event
  // which makes it too awkward for a little demo since you need to load the async data first
  source.start(0)
  // Create gain node and an analyser
  const gain = context.createGain()
  const analyser = context.createAnalyser()
  analyser.fftSize = 64
  source.connect(analyser)
  analyser.connect(gain)
  // The data array receive the audio frequencies
  const data = new Uint8Array(analyser.frequencyBinCount)
  return {
    context,
    source,
    gain,
    data,
    // This function gets called every frame per audio source
    update: () => {
      analyser.getByteFrequencyData(data)
      // Calculate a frequency average
      return (data.avg = data.reduce((prev, cur) => prev + cur / data.length, 0))
    },
  }
}


const InfoLayout = () => {

  return (
    <div id="info-wrap">
      <span>LEFT CLICK + DRAG TO ROTATE</span>
      <span>SCROLL TO ZOOM</span>
    </div>
  )
}